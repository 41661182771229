






















































































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  data() {
    return {
      options: {
        speed: 600,
        shortSwipes: false,
        longSwipesMs: 50,
        longSwipesRatio: 0.05,
        autoplay: true,
        loop: true
      }
    };
  },
  computed: {
    ...mapState("assets", {
      oss: "oss"
    }),
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    })
  }
});
